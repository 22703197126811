import Toast from 'common/toast';

export function prepareElmApp(appName, appDef, afterLoadCallback) {
  if (window.ElmInit === undefined) { window.ElmInit = {}; }

  window.ElmInit[appName] = { appDef: appDef, afterLoad: afterLoadCallback };
}

export function loadElmApp(appName) {
  const elmDiv = document.getElementById(`elm-${appName}`);
  const flagsElement = document.querySelector(`#${appName}-flags`);
  const flags = JSON.parse(flagsElement.innerText);

  flags.initial_url = window.location + '';

  const csrfMetaElement = document.querySelector('[name=csrf-token]');
  if (csrfMetaElement != null) {
    flags.csrf_token = csrfMetaElement.getAttribute('content');
  } else {
    // We need a value for this property in the case the application is
    // depending on it. Right now, in RAILS_ENV=test, we're not using CSRF
    // protection and therefore this isn't set in test.
    flags.csrf_token = 'not-a-real-csrf-value';
  }

  const appEntry = window.ElmInit[appName];
  const app = appEntry.appDef.Main.init({ node: elmDiv, flags: flags });

  Toast.setupForElm(app);
  setupRollbarForElm(app);
  setupRouterForElm(app);

  if (appEntry.afterLoad) {
    appEntry.afterLoad.call(this, app);
  }

  return app;
}

if (window.ElmInit === undefined) {
  window.ElmInit = {};
}

window.ElmInit.prepareApp = prepareElmApp;
window.ElmInit.loadApp = loadElmApp;

export default {
  prepareApp: prepareElmApp,
  loadApp: loadElmApp
};

function setupRollbarForElm(app) {
  if (app.ports.sendToRollbar === undefined) return;

  app.ports.sendToRollbar.subscribe(function(data) {
    const errorData = JSON.parse(data);

    switch (errorData.level) {
      case 'critical':
        Rollbar.critical(errorData.message);
        break;
      case 'error':
      default:
        Rollbar.error(errorData.message);
        break;
      case 'warning':
        Rollbar.warning(errorData.message);
        break;
      case 'info':
        Rollbar.info(errorData.message);
        break;
      case 'debug':
        Rollbar.debug(errorData.message);
        break;
    }
  });
}

function setupRouterForElm(app) {
  if (app.ports.onUrlChange === undefined) return;
  if (app.ports.pushUrl === undefined) return;
  if (app.ports.replaceUrl === undefined) return;

  window.addEventListener('popstate', function() {
    app.ports.onUrlChange.send(window.location.href);
  });

  app.ports.pushUrl.subscribe(function(url) {
    window.history.pushState({}, '', url);
    app.ports.onUrlChange.send(window.location.href);
  });

  app.ports.replaceUrl.subscribe(function(url) {
    window.history.replaceState({}, '', url);
    app.ports.onUrlChange.send(window.location.href);
  });
}
